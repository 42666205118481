<template>
  <fki-container
    fixed
    column
    top="196px"
    left="0"
    width="100%"
    class="fki-about-page"
  >
    <div
      class="fki-wrapper"
    >
      <fki-container
        class="fki-about-box"
        column
      >
        <span
          class="fki-about-content"
          v-html="page.body"
        />
        <p class="fki-contact-info">
          <span
            v-for="phone, index in page.telephones"
            :key="index"
            class="phone"
          >
            <a
              :href="`tel:+${formatTelephone(phone.number)}`"
            >+{{ phone.number }}</a>
          </span>
          <span
            v-for="address, index in page.addresses"
            :key="index"
            class="address"
          >
            {{ address.street1 }} {{ address.city }}, {{ address.state }}
            {{ address.zip }}
          </span>
          <span
            v-for="social, index in page.social"
            :key="index"
            class="social"
          >
            <a
              :href="social.link"
            >
              <img
                v-if="social.name == 'Facebook'"
                src="/images/facebook.png"
                alt="facebook"
              >
              <img
                v-if="social.name == 'Twitter'"
                src="/images/twitter.png"
                alt="Twitter"
              >
              <img
                v-if="social.name == 'Vimeo'"
                src="/images/vimeo.png"
                alt="Vimeo"
              >
              <img
                v-if="social.name == 'YouTube'"
                src="/images/youtube.png"
                alt="YouTube"
              >
            </a>
          </span>
        </p>
        <p
          v-if="page.linkName"
          class="about-link"
        >
          <a
            :href="page.link"
            target="_blank"
          >{{ page.linkName }}
          </a>
        </p>
      </fki-container>
      <fki-services
        :slides="page.slides"
        :services="services"
        :color="page.color"
      />
    </div>
    <fki-footer is-active>
      <fki-footer-nav is-active />
    </fki-footer>
  </fki-container>
  <fki-container-popup
    :is-active="showContactPopup"
    overlay
    persistent
    has-padding
    color="#424242"
    @close="closeContact()"
  >
    <fki-contact-form />
  </fki-container-popup>
</template>

<script>
// @ is an alias to /src
import Api from '@/services/api'
import { FKIContainer, FKIContainerPopup } from '@/components/FKIContainer'
import { FKIServices } from '@/components/FKIServices'
import { FKIFooterNav } from '@/components/FKINav'
import { FKIContactForm } from '@/components/FKIContact'
import { FKIFooter } from '@/components/FKIApp'

export default {
  name: 'Mmhw',
  components: {
    'fki-container': FKIContainer,
    'fki-services': FKIServices,
    'fki-container-popup': FKIContainerPopup,
    'fki-footer': FKIFooter,
    'fki-footer-nav': FKIFooterNav,
    'fki-contact-form': FKIContactForm
  },
  emits: ['isLoading', 'setBackgrounds'],
  data () {
    return {
      showContactPopup: false,
      showEmailResponsePopup: false,
      messageReponse: '',
      page: { color: 'blue', body: 'ON LOCATION - DATA, DEVICES, & DAILIES', slides: [] },
      services: []
    }
  },
  async created () {
    await this.getPage()
    await this.getServices(this.page.categoryIdentifier)
  },
  methods: {
    async checkParams () {
      await this.getPage()
      await this.getServices(this.page.categoryIdentifier)
    },
    async getPage () {
      this.isLoading = true

      try {
        this.$emit('isLoading', true)

        const { data: result } = await Api().get('/page/s/margarita-mix-hollywood')
        this.page = result
      } catch (err) {
        console.log(err)
      } finally {
        this.isLoading = false
        this.$emit('isLoading', false)
      }
    },
    async getServices (categoryIdentifier) {
      this.isLoading = true

      try {
        this.$emit('isLoading', true)

        const { data: result } = await Api().get('/services/c/' + categoryIdentifier)
        this.services = result
        this.$emit('setBackgrounds', this.page.slides)
      } catch (err) {
        console.log(err)
      } finally {
        this.isLoading = false
        this.$emit('isLoading', false)
      }
    },
    closeContact () {
      this.showContactPopup = false
    },
    openContact (item) {
      this.showContactPopup = true
    },
    closeEmailResponse () {
      this.showEmailResponsePopup = false
    },
    openEmailResponse (item) {
      this.showEmailResponsePopup = true
    },
    formatTelephone (input) {
      var inputLow = input.toLowerCase()
      var phonenumber = ''
      for (var i = 0; i < inputLow.length; i++) {
        var character = inputLow.charAt(i)
        switch (character) {
          case '0':
            phonenumber += '0'
            break
          case '1':
            phonenumber += '1'
            break
          case '2':
            phonenumber += '2'
            break
          case '3':
            phonenumber += '3'
            break
          case '4':
            phonenumber += '4'
            break
          case '5':
            phonenumber += '5'
            break
          case '6':
            phonenumber += '6'
            break
          case '7':
            phonenumber += '7'
            break
          case '8':
            phonenumber += '8'
            break
          case '9':
            phonenumber += '9'
            break
          case 'a':
          case 'b':
          case 'c':
            phonenumber += '2'
            break
          case 'd':
          case 'e':
          case 'f':
            phonenumber += '3'
            break
          case 'g':
          case 'h':
          case 'i':
            phonenumber += '4'
            break
          case 'j':
          case 'k':
          case 'l':
            phonenumber += '5'
            break
          case 'm':
          case 'n':
          case 'o':
            phonenumber += '6'
            break
          case 'p':
          case 'q':
          case 'r':
          case 's':
            phonenumber += '7'
            break
          case 't':
          case 'u':
          case 'v':
            phonenumber += '8'
            break
          case 'w':
          case 'x':
          case 'y':
          case 'z':
            phonenumber += '9'
            break
          case '-':
          case ' ':
          case '(':
          case ')':
            phonenumber += ''
            break
          default:
            phonenumber += character
        }
      }
      return phonenumber
    }
  }
}
</script>

<style lang="scss" scoped>
  .fki-about-page {
    bottom:0;
       .fki-wrapper {
    height: calc(100vh - 246px);
    display: flex;
    position: relative;
    float: left;
    width: 100%;
    height: auto;
    -webkit-overflow-scrolling: touch;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    }
      .fki-about-box {
        width: 80%;
        height: auto;
        padding: 25px 30px;
        margin: 0 auto 14px;
        position: relative;
        opacity: .8;
        background: #e2821e;
        background: linear-gradient(135deg,#e2821e 24%,#904d07 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e2821e', endColorstr='#904d07', GradientType=1 );
        span {
          text-align: left;
          margin-top: 0;
          color: #000;
          line-height: 1.3;
          font-size: 17px;
          a {
            color: #000;
            text-decoration: none;
          }
        }
        .fki-contact-info {
          padding: 10px 0;
          display: flex;
          position: relative;
          width: 100%;
          font-family: FFMarkWebProBold,"Helvetica Neue",Helvetica,Arial,sans-serif;
          margin-top: 12px;
          font-size: 14px;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          flex-wrap: nowrap;
          span {
             font-size: 14px;
             font-weight: bold;
             margin-bottom:8px;
            display: block;
          }
        }
      }
  }

  @media screen and (min-width: 768px) {
.fki-about-page {

   .fki-about-box {
        span {
          text-align: left;
          margin-top: 0;
          color: #000;
          line-height: 1.3;
          font-size: 17px;
          a {
            color: #000;
            text-decoration: none;
          }
        }
        .fki-contact-info {
          padding: 10px 0;
          display: flex;
          position: relative;
          width: 100%;
          font-family: FFMarkWebProBold,"Helvetica Neue",Helvetica,Arial,sans-serif;
          margin-top: 12px;
          font-size: 14px;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          flex-wrap: nowrap;
          span {
             font-weight: bold;
              font-size: 15px;
             margin-bottom:8px;
            display: block;
          }
        }
   }
}
}
</style>
