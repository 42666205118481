<template>
  <div class="embed-frame">
    <div v-if="vimeoId">
      <div class="embed-container">
        <iframe
          v-if="vimeoId"
          :src="'https://player.vimeo.com/video/' + vimeoId + '?autoplay=1'"
          frameborder="0"
          webkitAllowFullScreen
          mozallowfullscreen
          allowfullscreen
        />
      </div>
    </div>
  </div>
  <div class="fki-reels">
    <div
      v-for="item in reels"
      :key="item.id"
      class="fki-reel"
    >
      <div
        class="video thumb"
        @click="vimeoId = item.vimeoId"
      >
        <img :src="item.image.src">
        <span class="play">
          <strong>play</strong>
        </span>
      </div>
      <h4 class="credit-title">
        {{ item.title }}
      </h4>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FkiReels',
  components: {},
  props: {
    reels: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      vimeoId: null,
      showModal: false,
      modalIndex: '',

      video: {
        src: null,
        thumb: '',
        vimeoId: null,
        title: ''
      }
    }
  },
  methods: {
    modalPicker: function (x) {
      this.modalIndex = x
      alert(x)
      this.showModal = true
    }
  }
}
</script>
<style lang="scss" scoped>
.embed-frame {
  width: 100%;
  position: relative;
  margin: 10px 0;
}
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}
iframe {
  display: block;
  position: relative;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.fki-reels {
   margin-top:10px;
   display: flex;
   width:100%;
   flex-wrap: wrap;
   justify-content: space-evenly;
   .fki-reel {
    display: block;
    position: relative;
    width:30.3%;
    height:auto;
    padding:0 1.5%;
    overflow: hidden;
    margin-bottom:30px;
    cursor: pointer;
    img{
      display: block;
      width: 100%;
      height: auto;
    }
    .video {
      width: 100%;
      position: relative;
      .play {
        position: absolute;
        top: 50%;
        left: 50%;
        height: 40px;
        width: 40px;
        font-size: .7em;
        background: #fff;
        border-radius: 50%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        text-transform: uppercase;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
        opacity: .8;
        height: 60px;
        width: 60px;
        font-size:1em;
        color:#000;
        transition: opacity .35s ;
      }
    }
    .credit-title {
      color: #e6e6e6;
      text-align: center;
      font-size: 14px;
      margin: 6px;
      text-align: left;
    }
    &:hover {
      .play {
        opacity: 1;
      }
    }
  }
}

</style>
